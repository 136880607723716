import React from 'react'

function NotFound() {
    return (
        <div>
                <h2 className="ui center aligned header">
                  <span className="ui red header"> 404</span> Page Not Found
                  <p >Sorry, This page does not exist</p>
                </h2>
               
            </div>
    )
}
export default NotFound;

