import React, { Component } from 'react'

export class About extends Component {
    render() {
        return (
            <div>
                <h2 className="ui center aligned icon header">
                    <i className="circular users icon"></i>
                    About Page
                </h2>
                <p className="ui justifed">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt cum laboriosam ut eius atque? Dolore dolores saepe ipsum velit cumque, soluta mollitia eligendi consectetur modi asperiores quae, hic, quibusdam atque?Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente distinctio ipsum voluptatibus quidem labore perspiciatis, iste id quisquam, nobis laborum, ad sunt culpa voluptatem debitis eius ipsam repudiandae dolor cum dolorum accusantium corrupti numquam doloremque quaerat! Nisi ducimus iusto iure voluptatibus nesciunt eum eius distinctio voluptates. Quae dolorem commodi numquam similique maiores, delectus quisquam et recusandae quaerat? Iste omnis quibusdam libero tempora fugit aliquid culpa itaque optio, delectus voluptatibus! Excepturi aut facere minima animi amet quis dignissimos in tempora, corrupti, rem aliquam, facilis neque expedita asperiores ut accusamus repellat doloribus! Sapiente explicabo dolores obcaecati fugiat non nesciunt natus porro corporis.</p>
            </div>
        )
    }
}

export default About
